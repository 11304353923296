<template>
    <div class="animated fadeIn">
        <b-card>
            <b-card-header>
                <i class="icon-menu mr-1"></i>Data Table Pop Up
            </b-card-header>
            <b-card-body>
                <popup></popup>
            </b-card-body>
        </b-card>
    </div>
</template>

<script>
import popup from './Table'
export default {
    name: 'list-popup',
    components: {
        popup,
    },
}
</script>
